import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import CareersEqualOpportunity from "../../components/Careers/CareersEqualOpportunity"
import CareersBenefits from "../../components/Careers/CareersBenefits"
import CareersDepartments from "../../components/Careers/CareersDepartments"
import CareersHeadline from "../../components/Careers/CareersHeadline"
import { CareersTemplatepageData, TemplateProps } from "../templates"
import tw from "twin.macro"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const careers: React.FC = ({
  data,
  location,
}: TemplateProps<CareersTemplatepageData>) => {
  const { title, content, template } = data.wpPage
  const { benefitsSection, departmentsSection, equalOpportunitiesSection } =
    template.careersPage
  const image = getImage(
    data.wpPage.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <Image
          role="presentation"
          css={[tw`h-80`]}
          objectPosition="22% 0%"
          src={image}
          publicURL={data?.wpPage?.featuredImage?.node?.localFile?.publicURL}
          alt=""
        />
        <CareersHeadline title={title} content={content} />
        <CareersBenefits {...benefitsSection} />
        <CareersDepartments {...departmentsSection} />
        <CareersEqualOpportunity {...equalOpportunitiesSection} />
      </section>
    </div>
  )
}

export default careers

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      template {
        ... on WpTemplate_Careers {
          templateName
          careersPage {
            benefitsSection {
              description
              headline
            }
            departmentsSection {
              departmentCards {
                description
                title
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
              headline
              searchLink {
                target
                title
                url
              }
            }
            equalOpportunitiesSection {
              description
              headline
            }
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
