import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { CareersBenefitsProps } from "./Careers.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const CareersBenefits: React.FC<CareersBenefitsProps> = ({
  headline,
  description,
}) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-14 max-w-4xl mx-8 md:mx-auto`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <p
        css={[tw`text-center mt-5`]}
        dangerouslySetInnerHTML={{ __html: text?.description }}
      />
    </article>
  )
}

export default CareersBenefits
