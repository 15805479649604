import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { CareersHeadlineProps } from "./Careers.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const CareersHeadline: React.FC<CareersHeadlineProps> = ({
  title,
  content,
}) => {
  const [text, setText] = useState({ title, content })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article
      css={[
        tw`px-8 md:px-0 bg-gradient-to-b from-[#96e3ff38] to-white text-center`,
      ]}
    >
      <h1 css={[tw`text-3xl md:text-4xl font-extrabold pt-20`]}>
        {text?.title}
      </h1>
      <p
        css={[tw`mt-5 max-w-3xl mx-auto`]}
        dangerouslySetInnerHTML={{ __html: text?.content }}
      />
    </article>
  )
}

export default CareersHeadline
