import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import {
  CareersDepartmentsCardProps,
  CareersDepartmentsProps,
} from "./Careers.d"
import Button from "../Button/Button"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require ("../../helpers")
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"

const CareersDepartmentsCard: React.FC<CareersDepartmentsCardProps> = props => {
  const { title, description } = props
  const { backgroundImage } = props
  const image = getImage(
    backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
  )

  const [text, setText] = useState({ title, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div className="group" css={[tw`relative bg-[#202f45] hover:bg-[#007f7b] focus:bg-[#007f7b]`]} tabIndex={0}>
      <Image
        src={image}
        publicURL={backgroundImage?.localFile?.publicURL}
        role="presentation"
        css={[tw`h-[13rem] max-w-[23rem] opacity-25 grayscale`]}
        alt=""
      />
      <div
        css={[
          tw`top-0 left-0 absolute w-full h-full flex flex-col justify-center items-center gap-2 text-white p-6 text-center`,
        ]}
      >
        <Image
          src={props.icon?.localFile?.publicURL}
          css={[tw`hidden h-14 md:block md:group-hover:hidden md:group-focus:hidden`]}
          aria-hidden
          alt=""
        />
        <p css={[tw`text-white text-3xl`]}>{text?.title}</p>
        <p
          css={[tw`md:hidden md:group-hover:block md:group-focus:block`]}
          dangerouslySetInnerHTML={{ __html: text?.description }}
        />
      </div>
    </div>
  )
}

const CareersDepartments: React.FC<CareersDepartmentsProps> = ({
  headline,
  searchLink,
  departmentCards,
}) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-14 max-w-6xl mx-auto text-center`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <div css={[tw`flex flex-wrap justify-center gap-5 mt-8`]}>
        {departmentCards?.map((item, index) => (
          <CareersDepartmentsCard key={index} {...item} />
        ))}
      </div>
      <Button
        styles={[tw`mt-8`]}
        link={searchLink.url}
        text={searchLink.title}
        ariaLabel={searchLink.title}
      />
    </article>
  )
}

export default CareersDepartments
